@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

:root {
    --primary-color: #5cbf11;
}

*, *::before, *::after{
    margin: 0;
    padding: 0;
}

html {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.btn {
    font-family: 'Montserrat', sans-serif;
    background-color: transparent;
    border: 2px solid #fff;
    color: #fff;
    padding: .5em 1em;
    border-radius: .5em;
    font-size: 24px;
    cursor: pointer;
}


.primary_btn {
    font-family: 'Montserrat', sans-serif;
    background-color: #5CBF11;
    border: none;
    color: #fff;
    padding: .5em 1em;
    border-radius: .5em;
    font-size: 24px;
    cursor: pointer;

}

.logo {
    width: 48px;
    margin-right: 16px;
}

@media screen and (max-width: 768px) {
    
    body{ 
        overflow: hidden;
    }

}